import React from 'react';
import { File } from '@model/generated/index';
import { Layout } from '@components/global/Layout';
import { graphql, PageProps } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { navigationItemsProjects } from '@model/projekte/navigationItemsProjects';
import { Meta } from '@components/global/Meta';

type DataProps = {
  image1: File;
  image2: File;
  image3: File;
  image4: File;
};

type Props = PageProps<DataProps>;

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "projekte/fensterprosa/image1.jpeg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      publicURL
    }
    image2: file(relativePath: { eq: "projekte/fensterprosa/image2.jpeg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      publicURL
    }
    image3: file(relativePath: { eq: "projekte/fensterprosa/image3.jpeg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      publicURL
    }
    image4: file(relativePath: { eq: "projekte/fensterprosa/image4.jpeg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      publicURL
    }
  }
`;

const Page: React.FC<Props> = (props) => {
  const { data } = props;
  return (
    <Layout title="Projekte" navigationItems={navigationItemsProjects}>
      <div className="flex">
        <div className="flex-auto p-32">
          <iframe
            title="Jägerstraße von Vera Vorneweg"
            className="h-256 lg:h-512"
            frameBorder="0"
            allow="fullscreen; display-capture; autoplay"
            src="https://www.youtube.com/embed/vDk58gyrIzs"
            width="100%"
          />
          <div className="flex-1 py-32 mb-16">
            <h3>Fensterprosa - Vera Vorneweg</h3>
            <p>
              Die digital-analoge Literatur-Installation{' '}
              <b>„Fensterprosa - Eine andere Geschichte vom Verschwinden“</b>{' '}
              beschreibt die Welt aus der Perspektive von vier Barhockern, die
              in der Kneipe „Lönneberga“ in der Innenstadt Lüdenscheids stehen.
              Die von der Schriftstellerin <b>Vera Vorneweg</b> verfassten und
              auf die Stühle aufgetragenen Texte unternehmen den zarten und
              leisen Versuch, das am Fenster Vorbeiziehende sowie die Ereignisse
              im Barinnenraum literarisch zu skizzieren und sie in die
              Bar-Atmosphäre einzufügen. Die Künstlerin möchte mit ihrer Arbeit
              einladen, lesend vor den Stühlen zu verweilen und der mit allen
              Sinnen wahrnehmbaren Welt nachzuspüren.
            </p>
            <div className="my-32">
              <GatsbyImage
                className="max-w-672"
                objectFit="contain"
                image={data.image2.childImageSharp.gatsbyImageData}
                alt="Fensterprosa"
              />
              <small>© Andreas Endermann</small>
            </div>
            <iframe
              className="pb-32"
              title="Fensterprosa - Eine andere Geschichte vom Schmelzen"
              width="100%"
              height="320"
              scrolling="no"
              allow="autoplay"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1364849254&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
            />

            <p>
              <b>Vera Vorneweg</b> arbeitet seit 2020 mit Text im öffentlichen
              Raum. Zahlreiche Arbeiten sind in den letzten Monaten v.a. in
              Düsseldorf entstanden. <b>Vera Vorneweg</b> schreibt auf Wände,
              Steine, Autos, Stühle und Rollläden und ist ständig auf der Suche
              nach neuen Schreibuntergründen für ihre ortsspezifischen Texte.
              Während des Schreibprozesses strebt sie an, mit dem Gesehenen zu
              verschmelzen und die Welt aus der Perspektive der zu
              beschriftenden Gegenstände zu sehen. Die Texte sind in einer
              besonderen sprachlichen Form arrangiert und versuchen, das
              Flüchtige und ständig Vorbeiziehende mit Worten zu fixieren, um es
              der Vergänglichkeit zu entziehen.
            </p>
            <div className="flex flex-col my-32">
              <GatsbyImage
                className="my-8 md:my-0 md:-mb-40 lg:-mb-92 self-end z-10 w-full md:w-1/2"
                objectFit="cover"
                image={data.image1.childImageSharp.gatsbyImageData}
                alt="Fensterprosa"
              />
              <GatsbyImage
                className="my-8 md:my-0 max-w-672 w-full"
                objectFit="contain"
                image={data.image3.childImageSharp.gatsbyImageData}
                alt="Fensterprosa"
              />
              <GatsbyImage
                className="my-8 md:my-0 md:-mt-40 lg:-mt-92 self-end w-full md:w-1/2"
                objectFit="cover"
                image={data.image4.childImageSharp.gatsbyImageData}
                alt="Fensterprosa"
              />
              <small>© Marina Kiga</small>
            </div>
            <p>
              <b>Vera Vorneweg</b> (*1985) hat Soziale Arbeit und Philosophie in
              Düsseldorf und Israel studiert und publiziert neben Textarbeiten
              im öffentlichen Raum in Anthologien und Zeitschriften; zu Beginn
              2022 erscheint ihr Debütwerk in der Edition Muschelkalk im
              Wartburg-Verlag, Weimar. Sie erhielt diverse Preise und
              Stipendien, u.a. Harald-Gerlach-Preis, Stipendium „Künstler*innen
              im ländlichen Raum“, 1:1 Mentoringprogramm mit Marion Poschmann
              und Künstlerstipendium NRW.
            </p>
            <div className="pt-32">
              <b>
                <a
                  href="https://www.instagram.com/veravorneweg"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Zur Webseite der Künstlerin
                </a>
              </b>
            </div>
          </div>
        </div>
        <h1 style={{ writingMode: 'vertical-rl' }}>Fensterprosa</h1>
      </div>
    </Layout>
  );
};

export const Head: React.FC<Props> = (props) => {
  return (
    <Meta
      description="Eine andere Geschichte vom Verschwinden
      beschreibt die Welt aus der Perspektive von vier Barhockern, die
      in der Kneipe „Lönneberga“ in der Innenstadt Lüdenscheids stehen."
      publicURL={props.data.image2.publicURL}
      title="Fensterprosa"
    />
  );
};

export default Page;
